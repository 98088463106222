import React from "react";
import StyledLink from "../components/styledLink";
import { Layout } from "../components/layout";
import { Color, Variables } from "../components/designToken";
import notFoundImage from "../images/not_found.jpg";

const NotFoundPage: React.FC<{ location: Location }> = ({ location }) => (
  <>
    <style jsx>{`
      .section {
        padding: 220px 0 200px;
      }
      .section .sectionInner {
        background-image: url(${notFoundImage});
        background-size: cover;
        background-position: left bottom;
        background-repeat: no-repeat;
        max-width: none;
        height: 350px;
        padding: 0;
      }
      .textWrapper {
        background-color: rgba(255, 255, 255, 0.9);
        width: 30%;
        max-width: 230px;
        height: 100%;
        margin-left: 70%;
        padding: 33px 30px;
      }
      h1 {
        font-family: "Montserrat", sans-serif;
        line-height: 1;
        color: ${Color.orange};
        border-bottom: solid 1px ${Color.orange};
        padding-bottom: 15px;
      }
      h1 span {
        display: block;
      }
      h1 .number {
        font-size: 80px;
      }
      h1 .error {
        font-size: 63px;
      }
      p {
        margin-top: 15px;
        font-size: 14px;
        color: ${Color.defaultText};
      }
      .link {
        display: block;
        font-size: 16px;
      }
      @media (${Variables.breakPoint.sp}) {
        .section {
          padding: 150px 0 150px;
        }
        .section .sectionInner {
          position: relative;
          height: 380px;
        }
        .textWrapper {
          position: absolute;
          width: 205px;
          margin: 0 auto;
          top: -1px;
          right: 0;
          height: 320px;
          padding: 30px;
        }
        h1 .number {
          font-size: 70px;
        }
        h1 .error {
          font-size: 53px;
        }
        p {
          margin-top: 15px;
        }
        .link {
          margin-top: 5px;
        }
      }
    `}</style>
    <Layout ogpUrl={`https://about.refcome.com${location.pathname}/`}>
      <section className="section">
        <div className="sectionInner">
          <div className="textWrapper">
            <h1>
              <span className="number">404</span>
              <span className="error">Error</span>
            </h1>
            <p>
              申し訳ありません。
              <br />
              お探しのページは見つかりませんでした。
              <span className="link">
                <StyledLink href="/" text="トップへ戻る" />
              </span>
            </p>
          </div>
        </div>
      </section>
    </Layout>
  </>
);

export default NotFoundPage;
