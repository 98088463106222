import * as React from "react";
import { Color, Variables } from "./designToken";

interface Props {
  href: string;
  text: string;
  isTargetBlank?: boolean;
}

const StyledLink = (props: Props) => {
  return (
    <>
      <style jsx>{`
        a {
          color: ${Color.orange};
        }
        a:hover {
          text-decoration: underline;
        }
      `}</style>
      <a
        href={props.href}
        target={props.isTargetBlank ? "_blank" : "_self"}
        rel="noopener"
      >
        {props.text}
      </a>
    </>
  );
};
export default StyledLink;
